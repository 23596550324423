import {Pipe, PipeTransform} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FgPlanningHas} from './vkm-planning.component';
import {Utils} from '../utils.class';

@Pipe({
    name: 'sortTabs'
})
export class SortTabsPipe implements PipeTransform {

    transform(value: FormGroup<FgPlanningHas>[], ...args: unknown[]): FormGroup<FgPlanningHas>[] {
        return value
            .sort((a, b) => Utils.getTimeOrNull(a.value.begindate) - Utils.getTimeOrNull(b.value.begindate))
            .sort((a, b) => (a.value.type === 'MWO' ? 0 : 1) - (b.value.type === 'MWO' ? 0 : 1));
    }

}
