<div class="controls">
    <div class="header">
        <app-header [mainTitle]="'Planning DvdS'"
                    subTitle="Verkeersmaatregelen">
        </app-header>
    </div>
    <div class="indicator">

    </div>
    <div class="print-pdf">

    </div>
    <div class="actions">
        <app-settings-menu></app-settings-menu>
    </div>
</div>
<div class="info-bar" *ngIf="form && infoBar">
    <div class="container-xl">
        <div class="col">
            <div class="row pt-2 pb-2">
                <div class="col">
                    {{form.controls.planning_vkm.controls[currentTabIndex - 1] | nameTabs : form.controls.planning_vkm.controls}} -
                    {{form.value.planning_vkm[currentTabIndex - 1].begindate | date : 'EEEE d MMMM'}}
                </div>
                <div class="col text-right">
                    {{form.value.afas_project_id}} {{form.value.contractor ? '-' : ''}}
                    {{form.value.contractor}} {{form.value.location ? '-' : ''}}
                    {{form.value.location}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-xl pb-5" *ngIf="form && entitiesMap">
    <div>
        <div class="row">
            <div class="col-md-4">
                <ng-select [formControl]="form.controls.afas_project_id"
                           [typeahead]="searchAfasProjects$"
                           [clearable]="true"
                           [selectableGroup]="false"
                           [items]="projects"
                           class="ng-select-fill project-selection"
                           placeholder="Project"
                           groupBy="mainProject"
                           bindLabel="name"
                           bindValue="afas_project_id">
                    <ng-template ng-label-tmp let-item="item">
                        <b>{{item.afas_project_id}}</b> {{item.name}}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <b>{{item.afas_project_id}}</b> {{item.name}}
                    </ng-template>
                </ng-select>
            </div>
            <ng-container *ngIf="form.value.afas_project_id">
                <div class="col-md-4">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Opdrachtgever</mat-label>
                        <input [matAutocomplete]="autoContractors"
                               [formControl]="form.controls.contractor"
                               matInput
                               placeholder="Opdrachtgever"
                               type="text">
                        <mat-autocomplete #autoContractors="matAutocomplete" autoActiveFirstOption>
                            <mat-option *ngFor="let val of autocompleteContractors" [value]="val">
                                {{val}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error [control]="form.get('contractor')"></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <ng-select [formControl]="form.controls.location"
                               [typeahead]="searchLocations$"
                               [clearable]="true"
                               [selectableGroup]="false"
                               [items]="addresses"
                               class="ng-select-fill location-selection"
                               placeholder="Locatie"
                               bindLabel="formattedAddress"
                               bindValue="formattedAddress">
                    </ng-select>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="d-flex" *ngIf="form.value.afas_project_id">

        <mat-tab-group [(selectedIndex)]="currentTabIndex" animationDuration="0" #tabs class="w-100">
            <mat-tab [disabled]="true" labelClass="add-tab">
                <ng-template mat-tab-label>
                    <div class="curpoint" [matMenuTriggerFor]="menu">
                        <i class="fal fa-plus-circle"></i>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab *ngFor="let planning of form.controls.planning_vkm.controls | sortTabs : form.controls.planning_vkm.value?.length; let i = index">
                <ng-template mat-tab-label>
                    <div class="tab">
                        <div class="tab-name">
                            {{planning | nameTabs : form.controls.planning_vkm.controls}}
                        </div>
                        <small>{{planning.value.begindate | date : 'EE d MMM'}}</small>
                    </div>
                </ng-template>
                <div class="col mt-3">
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field appearance="fill" class="date">
                                        <mat-label>Datum</mat-label>
                                        <input (focus)="pickerStart.open()"
                                               [matDatepicker]="pickerStart"
                                               [formControl]="planning.controls.date.controls.date"
                                               matInput
                                               placeholder="Datum"
                                               tabindex="-1">
                                        <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
                                        <mat-datepicker #pickerStart></mat-datepicker>
                                        <mat-error [control]="planning.controls.begindate"></mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Van</mat-label>
                                        <mat-select [formControl]="planning.controls.date.controls.begintime" placeholder="van">
                                            <ng-container *ngIf="startTimes">
                                                <mat-optgroup *ngFor="let date of startTimes.get(planning)" label="{{date.datetime | date : 'EEEE d MMM'}}">
                                                    <mat-option *ngFor="let time of date.options" [disabled]="time.notAvailable" [value]="time.time">
                                                        {{time.datetime | date : 'HH:mm'}} uur
                                                    </mat-option>
                                                </mat-optgroup>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Tot</mat-label>
                                        <mat-select [formControl]="planning.controls.date.controls.endtime" placeholder="Tot tijd">
                                            <ng-container *ngIf="endTimes">
                                                <mat-optgroup *ngFor="let day of endTimes.get(planning)" label="{{day.datetime | date : 'EEEE d MMM'}}">
                                                    <mat-option *ngFor="let time of day.options" [disabled]="time.notAvailable" [value]="time.time">
                                                        {{time.datetime | date : 'HH:mm'}} uur
                                                    </mat-option>
                                                </mat-optgroup>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Uitvoerder</mat-label>
                                        <mat-select [formControl]="planning.controls.performer_id" placeholder="Uitvoerder">
                                            <mat-option *ngFor="let executor of executors" [value]="executor.id">{{executor.name}}</mat-option>
                                        </mat-select>
                                        <mat-error [control]="form.get('performer')"></mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Tekeningnr en type vkm</mat-label>
                                        <input type="text" [formControl]="planning.controls.drawing" matInput placeholder="Tekeningnr en type vkm">
                                        <mat-error [control]="planning.controls.drawing"></mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>V&G-plan</mat-label>
                                        <input type="text" [formControl]="planning.controls.vg_plan" matInput placeholder="V&G-plan">
                                        <mat-error [control]="planning.controls.vg_plan"></mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Omschrijving</mat-label>
                                        <textarea [formControl]="planning.controls.comment" matInput placeholder="Omschrijving" rows="4"></textarea>
                                        <mat-error [control]="planning.controls.comment"></mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <h2>Voorbereiding en uitvoering</h2>
                            <table class="table table-center table-add-row table-last-col">
                                <tr>
                                    <th>
                                        Materieelobject
                                    </th>
                                    <th>
                                        Aantal
                                    </th>
                                    <th>

                                    </th>
                                </tr>
                                <tr *ngFor="let workline of planning.controls.workorderMaterials.controls" [formGroup]="workline">
                                    <td class="select driver">
                                        <ng-select [formControl]="workline.controls.entity_id" placeholder="Materieel" appendTo="body" [clearable]="false">
                                            <ng-option [value]="m.id" *ngFor="let m of preparation" [innerHTML]="m.name"></ng-option>
                                        </ng-select>
                                    </td>
                                    <td class="number-input">
                                        <input appDecimalInput autocomplete="off"
                                               [formControl]="workline.controls.count_placed"
                                               matInput placeholder="0" type="text">
                                    </td>
                                    <td class="p-0">
                                        <button class="delete-button"
                                                (click)="removeWorkorderLine(planning.controls.workorderMaterials, workline)"
                                                color="warn"
                                                mat-icon-button
                                                tabindex="-1">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td [colSpan]="2">
                                        <button class="w-100" color="primary" mat-button
                                                (click)="addWorkoderLine(planning, planning.controls.workorderMaterials)">
                                            Toevoegen <i class="fas fa-plus"></i>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2>Materieelobject en aantallen</h2>
                        </div>
                        <table class="table table-center table-add-row table-last-col">
                            <tr>
                                <th>
                                    Materieelobject
                                </th>
                                <th *ngIf="fieldsByType.get(planning.value.type).has('planned')" class="px-0">
                                    {{fieldNameByType.get(planning.value.type).get('planned')}}
                                </th>
                                <th>
                                    {{fieldNameByType.get(planning.value.type).get('count')}}
                                </th>
                                <th>
                                    {{fieldNameByType.get(planning.value.type).get('date')}}
                                    <i (click)="setDates(planning, fieldsByType.get(planning.value.type).get('date'))"
                                       class="fa-duotone fa-solid fa-calendar-days"></i>
                                </th>
                                <th *ngIf="fieldsByType.get(planning.value.type).has('lost')">
                                    {{fieldNameByType.get(planning.value.type).get('lost')}}
                                </th>
                                <th>
                                    Toelichting
                                </th>
                                <th>
                                    CROW
                                </th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let workline of planning.controls.workorderObjects.controls" [formGroup]="workline">
                                <ng-container *ngIf="workline.value.comment !== 'text'">
                                    <td class="select driver">
                                        <ng-select [formControl]="workline.controls.entity_id" placeholder="Materieel" appendTo="body" [clearable]="false">
                                            <ng-option [value]="m.id" *ngFor="let m of materiaal" [innerHTML]="m.name"></ng-option>
                                        </ng-select>
                                    </td>
                                    <td class="number-input" *ngIf="fieldsByType.get(planning.value.type).has('planned')">
                                        <input appDecimalInput autocomplete="off"
                                               [formControlName]="fieldsByType.get(planning.value.type).get('planned')"
                                               matInput placeholder="0" type="text">
                                    </td>
                                    <td class="number-input">
                                        <div class="d-flex align-items-center">
                                            <input appDecimalInput autocomplete="off"
                                                   [formControlName]="fieldsByType.get(planning.value.type).get('count')"
                                                   matInput placeholder="0" type="text">
                                            <i *ngIf="planning.value.type === 'DWO'"
                                               class="fa-duotone fa-solid fa-calculator-simple"
                                               (click)="calc(workline, fieldsByType.get(planning.value.type).get('count'))"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <input (focus)="pickerStart.open()"
                                                   (click)="pickerStart.open()"
                                                   [matDatepicker]="pickerStart"
                                                   [formControlName]="fieldsByType.get(planning.value.type).get('date')"
                                                   matInput
                                                   placeholder="Datum">
                                            <mat-datepicker #pickerStart></mat-datepicker>
                                            <i class="curpoint fa-duotone fa-solid fa-calendar-days"
                                               (click)="setDate(planning, workline, fieldsByType.get(planning.value.type).get('date'))"></i>
                                        </div>
                                    </td>
                                    <td *ngIf="fieldsByType.get(planning.value.type).has('lost')" class="number-input">
                                        <div class="d-flex align-items-center">
                                            <input appDecimalInput autocomplete="off"
                                                   [formControlName]="fieldsByType.get(planning.value.type).get('lost')"
                                                   matInput placeholder="0" type="text">
                                            <i *ngIf="planning.value.type === 'DWO'"
                                               class="fa-duotone fa-solid fa-calculator-simple"
                                               (click)="calc(workline, fieldsByType.get(planning.value.type).get('lost'))"></i>
                                        </div>
                                    </td>
                                    <td class="text-input">
                                        <textarea [formControl]="workline.controls.comment"
                                                  rows="1"
                                                  cdkTextareaAutosize
                                                  matInput
                                                  placeholder="Opmerking"></textarea>
                                    </td>
                                    <td class="p-0"
                                        (click)="workline.controls.checked_at.setValue(workline.value.checked_at ? null : planning.value.begindate); workline.controls.checked_at.markAsDirty();">
                                        <mat-checkbox [formControl]="workline.controls.checked_at" (click)="$event.preventDefault()"></mat-checkbox>
                                    </td>
                                    <td class="p-0">
                                        <button class="delete-button"
                                                (click)="removeWorkorderLine(planning.controls.workorderObjects, workline)"
                                                color="warn"
                                                mat-icon-button
                                                tabindex="-1">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="workline.value.comment === 'text'">
                                    <td class="text-input" colspan="6">
                                        <textarea [formControl]="workline.controls.description" rows="1" cdkTextareaAutosize matInput
                                                  placeholder="Opmerking"></textarea>
                                    </td>
                                    <td class="p-0">
                                        <button class="delete-button"
                                                (click)="removeWorkorderLine(planning.controls.workorderObjects, workline)"
                                                color="warn"
                                                mat-icon-button
                                                tabindex="-1">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </ng-container>
                            </tr>
                            <tr>
                                <td [colSpan]="(3 + fieldsByType.get(planning.value.type).size)">
                                    <div class="d-flex">
                                        <button class="w-100" color="primary" mat-button
                                                (click)="addWorkoderLine(planning, planning.controls.workorderObjects)">
                                            Toevoegen object <i class="fas fa-plus"></i>
                                        </button>
                                        <button class="w-100" color="primary" mat-button (click)="addWorkoderLineText(planning.controls.workorderObjects)">
                                            Toevoegen beschrijving <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </mat-tab>
            <mat-tab labelClass="calculation" label="Afrekenstaat">
                <div class="col mt-3">
                    <table class="table table-center">
                        <tr>
                            <th>
                                Materieel
                            </th>
                            <th>
                                Aanvang
                            </th>
                            <th>
                                Einde
                            </th>
                            <th>
                                Dagen
                            </th>
                            <th>
                                Geleverd
                            </th>
                            <th>
                                Huurdagen
                            </th>
                            <th>
                                Tarief
                            </th>
                            <th>
                                Kosten
                            </th>
                            <th>
                                Kwijt
                            </th>
                            <th>
                                Tarief
                            </th>
                            <th>
                                Kosten
                            </th>
                        </tr>
                        <tr *ngFor="let calc of objectCalculations">
                            <td>
                                {{entitiesMap.get(calc.entity_id)?.name}}
                            </td>
                            <td>
                                {{calc.date_placed | date : 'dd-MM-yyyy'}}
                            </td>
                            <td>
                                {{calc.date_removed | date : 'dd-MM-yyyy'}}
                            </td>
                            <td>
                                {{calc.days}}
                            </td>
                            <td>
                                {{calc.count_placed}}
                            </td>
                            <td>
                                {{calc.total_days}}
                            </td>
                            <td class="text-right">
                                {{calc.price_unit | currency : 'EUR'}}
                            </td>
                            <td class="text-right">
                                {{calc.price_unit_total | currency : 'EUR'}}
                            </td>
                            <td>
                                {{calc.count_lost}}
                            </td>
                            <td class="text-right">
                                <ng-container *ngIf="calc.count_lost">
                                    {{calc.price | currency : 'EUR'}}
                                </ng-container>
                            </td>
                            <td class="text-right">
                                <ng-container *ngIf="calc.count_lost">
                                    {{calc.price_total | currency : 'EUR'}}
                                </ng-container>
                            </td>
                        </tr>
                    </table>

                    <div class="row">
                        <div class="col">
                            <table class="table table-center">
                                <tr>
                                    <th>
                                        Voorbereiding en uitvoering
                                    </th>
                                    <th>
                                        Aantal
                                    </th>
                                    <th>
                                        Tarief
                                    </th>
                                    <th>
                                        Kosten
                                    </th>
                                </tr>
                                <tr *ngFor="let calc of materialCalculations">
                                    <td>
                                        {{entitiesMap.get(calc.entity_id)?.name}}
                                    </td>
                                    <td>
                                        {{calc.count_placed}}
                                    </td>
                                    <td class="text-right">
                                        {{calc.price_unit | currency : 'EUR'}}
                                    </td>
                                    <td class="text-right">
                                        {{calc.price_unit_total | currency : 'EUR'}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col">
                            <table class="table">
                                <tr>
                                    <th>
                                        Afrekenstaat
                                    </th>
                                    <th>
                                        Kosten
                                    </th>
                                </tr>
                                <tr *ngFor="let calc of totalPrices">
                                    <td>
                                        {{calc.settle_group}}
                                    </td>
                                    <td class="text-right">
                                        {{calc.price | currency : 'EUR'}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Totaal</b>
                                    </td>
                                    <td class="text-right">
                                        <b>{{totalPrice | currency : 'EUR'}}</b>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>


            </mat-tab>
        </mat-tab-group>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="addWorkorder('MWO')">Monteren</button>
            <button mat-menu-item (click)="addWorkorder('DWO')">Demonteren</button>
        </mat-menu>
    </div>
</div>

<div class="button-wrapper">
    <div class="container-xl">
        <div class="buttons" *ngIf="form">
            <button mat-raised-button color="accent" routerLink="/">
                Naar overzicht
            </button>
            <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.dirty || saving">
                {{saved ? 'Wijzigingen opgeslagen' : form.dirty ? 'Openstaande wijzigingen opslaan' : 'Er zijn geen wijzigingen'}}
                <i class="fas fa-spinner fa-spin" *ngIf="saving"></i>
            </button>
        </div>
    </div>
</div>
