import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {Planning} from '../classes/planning.class';
import {Project} from '../classes/project.class';
import {debounceTime, first, throttleTime} from 'rxjs/operators';
import {asyncScheduler, fromEvent, Subject} from 'rxjs';
import {ProjectService} from '../services/project.service';
import {AutocompleteService, AutocompleteType} from '../services/planning/autocomplete.service';
import {User} from '../classes/user.class';
import {UserService, UserType} from '../services/user/user.service';
import {LocationService} from '../services/location.service';
import {Workorderline} from '../classes/workorderline';
import {EntitiesService} from '../services/entities/entities.service';
import {EntityTypeCode} from '../services/entities/entity-type.class';
import {Entity} from '../classes/entity.class';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {SettleGroupPrice, VkmMaterialCalculation, VkmObjectCalculation, VkmService} from '../services/vkm.service';
import {Utils} from '../utils.class';
import {ActivatedRoute, Router} from '@angular/router';
import {PlanningService} from '../services/planning/planning.service';
import {SortTabsPipe} from './sort-tabs.pipe';
import {DateAdapter} from '@angular/material/core';
import {DateFullMonthAdapter} from '../date-adapters/date-full-month-adapter';
import {DayTimeOptions} from '../classes/day-time-options';
import {TimeOption} from '../classes/time-option';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-vkm-planning',
    templateUrl: './vkm-planning.component.html',
    styleUrls: ['./vkm-planning.component.scss'],
    providers: [{
        provide: DateAdapter, useClass: DateFullMonthAdapter
    }]
})
export class VkmPlanningComponent extends CodaltComponent implements OnInit {

    infoBar = false;
    mayReload = false;
    saving = false;
    saved = false;
    currentTabIndex = -1;

    projects: Project[];
    searchAfasProjects$ = new Subject<string>();
    autocompleteContractors: string[];
    searchLocations$ = new Subject<string>();

    executors: User[];

    form: FormGroup<{
        id: FormControl<number>,
        contractor: FormControl<string>,
        afas_project_id: FormControl<string>,
        location: FormControl<string>,
        planning_vkm: FormArray<FormGroup<FgPlanningHas>>,
        updated_at: FormControl<Date>
    }>;

    addresses: {
        addressLine,
        adminDistrict,
        adminDistrict2,
        countryRegion,
        formattedAddress,
        locality,
        postalCode
    }[];

    fieldNameByType = new Map<string, Map<string, string>>([
        ['MWO', new Map<string, string>([
            ['planned', 'Vkm-plan'],
            ['count', 'Geplaatst'],
            ['date', 'Leverdatum']
        ])],
        ['DWO', new Map<string, string>([
            ['lost', 'Kwijt'],
            ['count', 'Retour'],
            ['date', 'Retourdatum']
        ])]
    ]);

    fieldsByType = new Map<string, Map<string, string>>([
        ['MWO', new Map<string, string>([
            ['planned', 'count_planned'],
            ['count', 'count_placed'],
            ['date', 'date_placed']
        ])],
        ['DWO', new Map<string, string>([
            ['lost', 'count_lost'],
            ['count', 'count_removed'],
            ['date', 'date_removed']
        ])]
    ]);

    materiaal: Entity[];
    preparation: Entity[];
    entitiesMap: Map<number, Entity>;

    objectCalculations: VkmObjectCalculation[];
    materialCalculations: VkmMaterialCalculation[];
    totalPrices: SettleGroupPrice[];
    totalPrice: number;

    startTimes = new Map<FormGroup<FgPlanningHas>, DayTimeOptions[]>();
    endTimes = new Map<FormGroup<FgPlanningHas>, DayTimeOptions[]>();

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private planningService: PlanningService,
                private projectService: ProjectService,
                private autocompleteService: AutocompleteService,
                private locationService: LocationService,
                private userService: UserService,
                private entitiesService: EntitiesService,
                private vkmService: VkmService) {
        super();
    }

    calc(fgWl: FormGroup<FgWorkorderLine>, field: string) {
        const mwos = this.form.value.planning_vkm.filter(p => p.type === 'MWO').map(p => p.workorderObjects).flat().filter(o => o.entity_id === fgWl.value.entity_id);
        const dwos = this.form.value.planning_vkm.filter(p => p.type === 'DWO').map(p => p.workorderObjects).flat().filter(o => o.entity_id === fgWl.value.entity_id);
        const lost = mwos.reduce((sum, current) => sum + current.count_placed, 0) - dwos.reduce((sum, current) => sum + current.count_removed, 0);
        fgWl.get(field).setValue(lost);
    }

    setDates(planningHas: FormGroup<FgPlanningHas>, field: string) {
        planningHas.controls.workorderObjects.controls.forEach(fg => {
            fg.get(field).setValue(Utils.setTime(new Date(planningHas.value.begindate), 0, 0));
        });
        planningHas.markAsDirty();
    }

    setDate(planningHas: FormGroup<FgPlanningHas>, fgWl: FormGroup<FgWorkorderLine>, field: string) {
        fgWl.get(field).setValue(Utils.setTime(new Date(planningHas.value.begindate), 0, 0));
        fgWl.markAsDirty();
    }

    genStartAndEndTimes(planningHas: FormGroup<FgPlanningHas>) {
        const startDate = new Date(planningHas.controls.date.controls.date.value);

        function genTimes(fromDate, toDate) {
            const times: Date[] = [];
            const countDate = new Date(fromDate);
            while (countDate.getTime() <= toDate.getTime()) {
                times.push(new Date(countDate));
                countDate.setUTCMinutes(countDate.getUTCMinutes() + 30);
            }
            const dayOptionsList: DayTimeOptions[] = [];
            let dayOptions = new DayTimeOptions();
            times.forEach((time, i) => {
                if (!dayOptions.datetime || dayOptions.datetime.getDay() !== time.getDay() || i === times.length - 1) {
                    if (dayOptions.datetime) {
                        dayOptionsList.push(dayOptions);
                    }
                    dayOptions = new DayTimeOptions();
                    dayOptions.datetime = time;
                }
                const timeOption = new TimeOption(time);
                dayOptions.options.push(timeOption);
            });
            return dayOptionsList;
        }

        const dayOptionsListStart = genTimes(Utils.setTime(new Date(startDate), 0, 0), Utils.setTime(new Date(startDate), 24, 0));
        this.startTimes.set(planningHas, dayOptionsListStart);

        const startEndTimes = new Date(planningHas.controls.date.value.begintime);
        if (startEndTimes.getMinutes() > 30) {
            startEndTimes.setHours(startEndTimes.getHours() + 1);
            startEndTimes.setMinutes(0);
        } else {
            startEndTimes.setMinutes(30);
        }
        const endEndTimes = new Date(planningHas.controls.date.value.begintime);
        endEndTimes.setDate(endEndTimes.getDate() + 1);

        const dayOptionsListEnd = genTimes(Utils.setTime(new Date(startDate), startEndTimes.getHours(), startEndTimes.getMinutes()), endEndTimes);
        this.endTimes.set(planningHas, dayOptionsListEnd);
    }

    ngOnInit(): void {
        this.subscriptions.add(fromEvent(document.getElementById('main-container'), 'scroll')
            .pipe(throttleTime(100, asyncScheduler, {leading: false, trailing: true}))
            .subscribe((e: Event) => {
                this.showInfoBar();
            }));

        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { id, fromDate, toDate }) => {
            if (params.id && params.id != 'new') {
                this.getPlanning(+params.id);
            } else {
                this.createForm(null, params.fromDate, params.toDate);
            }
        }));

        this.subscriptions.add(this.userService.getByType(UserType.EXECUTOR).subscribe(users => this.executors = users));

        this.subscriptions.add(this.searchAfasProjects$.pipe(debounceTime(200)).subscribe(search => {
            this.subscriptions.add(this.projectService.searchProjects(search).subscribe((projectResponse) => {
                this.projects = projectResponse.data?.filter(p => !!p.parent_id);
            }));
        }));

        this.subscriptions.add(this.searchLocations$.pipe(debounceTime(200)).subscribe(search => {
            const addresses = [];
            this.subscriptions.add(this.locationService.getLocations(search + ' Nederland').subscribe(result => {
                result.resourceSets.forEach(res => {
                    res.resources.forEach(address => {
                        addresses.push(address.address);
                    });
                });
                this.addresses = addresses;
            }));
        }));

        this.subscriptions.add(this.entitiesService.getByType(EntityTypeCode.VkmMateriaal).subscribe(entities => {
            this.materiaal = entities;
        }));
        this.subscriptions.add(this.entitiesService.getByType(EntityTypeCode.VkmVoorbereiding).subscribe(entities => {
            this.preparation = entities;
        }));
        this.subscriptions.add(this.entitiesService.getMap().pipe(debounceTime(500)).subscribe(entitiesMap => {
            this.entitiesMap = entitiesMap;
        }));
    }

    getPlanning(id?: number) {
        this.subscriptions.add(this.planningService.getSingle(id).subscribe(planning => {
            if (!this.form || this.mayReload) {
                this.mayReload = false;
                this.createForm(planning);
            }
        }));
    }

    addWorkorder(type, fromDate?: any, toDate?: any) {
        const wo = this.createWorkorder(null, type, fromDate, toDate);
        this.form.controls.planning_vkm.push(wo);

        this.currentTabIndex = (new SortTabsPipe()).transform(this.form.controls.planning_vkm.controls).indexOf(wo) + 1;
    }

    addWorkoderLine(fg: FormGroup<FgPlanningHas>, formArray: FormArray<FormGroup<FgWorkorderLine>>) {
        formArray.push(this.createWorkoderLine());
    }

    addWorkoderLineText(formArray: FormArray<FormGroup<FgWorkorderLine>>) {
        formArray.push(this.createWorkoderLine(null, 'text'));
    }

    createWorkorder(ph: PlanningHasEntity, type?: 'MWO' | 'DWO', fromDate?: any, toDate?: any): FormGroup<FgPlanningHas> {
        const beginDate = ph?.begindate ?? fromDate ?? Utils.setTime(new Date(), new Date().getHours(), 0);
        const endDate = ph?.enddate ?? toDate ?? Utils.setTime(new Date(), new Date().getHours() + 1, 0);
        const fg = new FormGroup<FgPlanningHas>({
            id: new FormControl(ph?.id),
            type: new FormControl(ph?.type ?? type),
            drawing: new FormControl(ph?.drawing),
            vg_plan: new FormControl(ph?.vg_plan),
            comment: new FormControl(ph?.comment),
            performer_id: new FormControl(ph?.performer_id),
            date: new FormGroup({
                date: new FormControl(beginDate),
                begintime: new FormControl(new Date(beginDate).getTime()),
                endtime: new FormControl(new Date(endDate).getTime())
            }),
            begindate: new FormControl(beginDate),
            enddate: new FormControl(endDate),
            workorderObjects: new FormArray(ph?.workorder_lines
                ?.filter(wl => wl.entity?.entitytypes.map(et => et.id).includes(EntityTypeCode.VkmMateriaal) || wl.comment === 'text')
                .map(wl => this.createWorkoderLine(wl)) ?? this.createDefaultObjects()
            ),
            workorderMaterials: new FormArray(ph?.workorder_lines
                ?.filter(wl => wl.entity?.entitytypes.map(et => et.id).includes(EntityTypeCode.VkmVoorbereiding))
                .map(wl => this.createWorkoderLine(wl)) ?? this.createDefaultPreperations()
            ),
            updated_at: new FormControl(ph?.updated_at)
        });
        this.genStartAndEndTimes(fg);
        this.subscriptions.add(fg.controls.date.valueChanges.subscribe(date => {
            const newBeginTime = new Date(date.date);
            const begintime = new Date(date.begintime);
            newBeginTime.setHours(begintime.getHours());
            newBeginTime.setMinutes(begintime.getMinutes());
            const newEndTime = new Date(date.date);
            const endtime = new Date(date.endtime);
            newEndTime.setHours(endtime.getHours());
            newEndTime.setMinutes(endtime.getMinutes());
            fg.controls.date.controls.begintime.setValue(newBeginTime.getTime(), {emitEvent: false});
            fg.controls.date.controls.endtime.setValue(newEndTime.getTime(), {emitEvent: false});
            fg.controls.begindate.setValue(new Date(newBeginTime.getTime()), {emitEvent: false});
            fg.controls.enddate.setValue(new Date(newEndTime.getTime()), {emitEvent: false});
            this.genStartAndEndTimes(fg);
        }));

        return fg;
    }

    createWorkoderLine(wl?: Workorderline, comment?: string) {
        return new FormGroup<FgWorkorderLine>({
            id: new FormControl(wl?.id),
            planning_has_id: new FormControl(wl?.planning_has_id),
            description: new FormControl(wl?.description),
            entity_id: new FormControl(wl?.entity_id),
            count_planned: new FormControl(wl?.count_planned),
            count_placed: new FormControl(wl?.count_placed),
            count_removed: new FormControl(wl?.count_removed),
            count_lost: new FormControl(wl?.count_lost),
            date_placed: new FormControl(wl?.date_placed),
            date_removed: new FormControl(wl?.date_removed),
            comment: new FormControl(wl?.comment ?? comment),
            checked_at: new FormControl(wl?.checked_at),
            checked_by: new FormControl(wl?.checked_by),
            order: new FormControl(wl?.order),
            updated_at: new FormControl(wl?.updated_at)
        });
    }

    removeWorkorderLine(formArray: FormArray<FormGroup<FgWorkorderLine>>, fgWl: FormGroup<FgWorkorderLine>) {
        if (!fgWl.value.count_placed && !fgWl.value.count_planned && !fgWl.value.count_removed && !fgWl.value.date_removed && !fgWl.value.date_placed) {
            const index = formArray.controls.indexOf(fgWl);
            formArray.removeAt(index);
        } else {
            this.confirmDialog.confirm(
                'Object verwijderen',
                'Weet je zeker dat je dit object wilt verwijderen?',
                'Verwijderen', 'Behouden').then(() => {
                const index = formArray.controls.indexOf(fgWl);
                formArray.removeAt(index);
            }, () => {
            });
        }
        formArray.markAsDirty();
    }

    createDefaultObjects() {
        return this.materiaal.map(m => {
            return this.createWorkoderLine({
                entity_id: m.id
            });
        });
    }

    createDefaultPreperations() {
        return this.preparation.map(m => {
            return this.createWorkoderLine({
                entity_id: m.id
            });
        });
    }

    countAndPrices() {
        if (this.form.value?.id) {
            this.subscriptions.add(this.vkmService.countsAndPrices(this.form.value?.id).subscribe(calculations => {
                this.objectCalculations = calculations.data.objects;
                this.materialCalculations = calculations.data.materials;
                this.totalPrices = calculations.data.totalPrices;
                this.totalPrice = calculations.data.totalPrice;
            }));
        }
    }

    createForm(planning?: Planning, fromDate?: any, toDate?: any) {
        this.form = new FormGroup({
            id: new FormControl(planning?.id),
            updated_at: new FormControl(planning?.updated_at),
            afas_project_id: new FormControl<string>(planning?.afas_project_id),
            contractor: new FormControl<string>(planning?.contractor),
            location: new FormControl<string>(planning?.location),
            planning_vkm: new FormArray(planning?.planning_vkm?.map(ph => this.createWorkorder(ph)) ?? [])
        });
        if (!planning?.id) {
            this.subscriptions.add(this.entitiesService.getByType(EntityTypeCode.VkmMateriaal).pipe(debounceTime(500), first()).subscribe(() => {
                this.addWorkorder('MWO', fromDate, toDate);
            }));
        }

        if (!this.form.value.id) {
            this.subscriptions.add(this.form.controls.afas_project_id.valueChanges.subscribe(projectId => {
                this.subscriptions.add(this.vkmService.idByProject(projectId).subscribe(planningId => {
                    this.mayReload = true;
                    if (planningId.data) {
                        this.getPlanning(planningId.data);
                        this.router.navigateByUrl(`vkm/${planningId.data}`, {replaceUrl: true});
                    }
                }));
            }));
        }

        this.subscriptions.add(this.form.controls.contractor.valueChanges.subscribe(value => {
            value = value ? value : '';
            const sub = this.autocompleteService.getByType(AutocompleteType.contractors).subscribe(autoItems => {
                this.autocompleteContractors = autoItems.filter(a => a.toLowerCase().includes(('' + value).toLowerCase()));
            });
            sub.unsubscribe();
        }));
        this.countAndPrices();
        if (this.currentTabIndex < 1) {
            setTimeout(() => {
                this.currentTabIndex = 0;
                setTimeout(() => {
                    this.currentTabIndex = 1;
                });
            });
        }
    }

    save() {
        this.saving = true;
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            const planning = new Planning();
            Object.assign(planning, this.form.value);
            this.mayReload = true;
            this.subscriptions.add(this.vkmService.save(planning).subscribe(result => {
                this.saving = false;
                this.form.markAsPristine();
                this.router.navigateByUrl(`vkm/${result.data.id}`, {replaceUrl: true});
            }, () => {
                this.saving = false;
            }));
        }
    }

    isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return rect.top <= (window.innerHeight || document.documentElement.clientHeight) && (rect.top > -100 || rect.bottom > -100);
    }

    showInfoBar() {
        this.infoBar = !this.isInViewport(document.querySelector('.project-selection'));
    }
}

export interface FgPlanningHas {
    id: FormControl<number>,
    type: FormControl<'MWO' | 'DWO'>,
    drawing: FormControl<string>,
    vg_plan: FormControl<string>,
    comment: FormControl<string>,
    performer_id: FormControl<number>,
    date: FormGroup<{
        date: FormControl<Date>,
        begintime: FormControl<number>,
        endtime: FormControl<number>,
    }>,
    begindate: FormControl<Date>,
    enddate: FormControl<Date>,
    workorderObjects: FormArray<FormGroup<FgWorkorderLine>>,
    workorderMaterials: FormArray<FormGroup<FgWorkorderLine>>,
    updated_at: FormControl<Date>
}

export interface FgWorkorderLine {
    id: FormControl<number>,
    planning_has_id: FormControl<number>,
    description: FormControl<string>,
    entity_id: FormControl<number>,
    count_planned: FormControl<number>,
    count_placed: FormControl<number>,
    count_removed: FormControl<number>,
    count_lost: FormControl<number>,
    date_placed: FormControl<Date>,
    date_removed: FormControl<Date>,
    comment: FormControl<string>,
    checked_at: FormControl<Date>,
    checked_by: FormControl<number>,
    order: FormControl<number>,
    updated_at: FormControl<Date>
}
