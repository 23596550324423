import {FormControl, FormGroup} from '@angular/forms';
import {of, timer} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {PlanningService} from '../services/planning/planning.service';

export const daterangeAvailableValidator = (planningService: PlanningService,
                                            entityId: number,
                                            checkType = DateRangeValidatorType.normal,
                                            skipUnavailable: boolean
) => {
    return (input: FormGroup | FormControl) => {
        if (!input.dirty) {
            return of(null);
        }
        let begindate = new Date();
        let enddate = new Date();

        if (input instanceof FormGroup) {
            begindate = input.get('begindate').value;
            enddate = input.get('enddate').value;
        } else {
            begindate = input.value;
            enddate = input.value;
        }

        if (checkType === DateRangeValidatorType.normal) {
            begindate.setHours(0);
            begindate.setMinutes(0);
            begindate.setSeconds(0);
            enddate.setHours(23);
            enddate.setMinutes(59);
            enddate.setSeconds(59);
        }

        if (checkType === DateRangeValidatorType.endAsBegin) {
            if (!enddate) {
                return of(null);
            }
            begindate = new Date(enddate);
            enddate = new Date(enddate);
            enddate.setFullYear(2999);
        }
        if (checkType === DateRangeValidatorType.beginAsEnd) {
            if (!begindate) {
                return of(null);
            }
            enddate = new Date(begindate);
            begindate = new Date();
            begindate.setFullYear(1970);
        }

        begindate = new Date(begindate);
        enddate = new Date(enddate);

        return timer(500).pipe(
            switchMap(() => planningService.checkEntityAvailability(
                entityId,
                null,
                begindate,
                enddate,
                skipUnavailable
            )),
            map(res => res ? {notAvailable: res} : null)
        );
    };
};

export enum DateRangeValidatorType {
    normal, endAsBegin, beginAsEnd
}
