import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Planning} from '../classes/planning.class';

@Injectable({
    providedIn: 'root'
})
export class VkmService {

    constructor(private apiService: ApiService) {
    }

    countsAndPrices(planningId) {
        return this.apiService.getCall$<{
            objects: VkmObjectCalculation[],
            materials: VkmMaterialCalculation[],
            totalPrices: SettleGroupPrice[],
            totalPrice: number
        }>(`vkm/counts-and-prices/${planningId}`);
    }

    save(planning: Planning) {
        return this.apiService.postCall$<Planning>('vkm', planning);
    }

    idByProject(afasProjectId: string) {
        return this.apiService.getCall$<number>(`vkm/id-by-project/${afasProjectId}`);
    }
}

export class VkmObjectCalculation {
    count_lost: number;
    count_placed: number;
    count_removed: number;
    date_placed: Date;
    date_removed: Date;
    entity_id: number;
    price: number;
    price_total: number;
    price_unit: number;
    price_unit_total: number;
    total_days: number;
    days: number;
}

export class VkmMaterialCalculation {
    count_placed: number;
    entity_id: number;
    price: number;
    price_total: number;
    price_unit: number;
    price_unit_total: number;
}

export class SettleGroupPrice {
    settle_group: string;
    price: number;
}
